<template>
    <div class="h-full w-full flex flex-col justify-between items-center space-y-6">
        <div
            class="w-full h-1/2 md:flex flex-row justify-between items-center md:space-x-6 p-2 md:p-0 rounded bg-white md:bg-transparent"
        >
            <div class="h-1/2 md:h-full bg-white rounded p-3 w-full md:w-1/4 flex flex-col justify-center items-center"
            :class="{
                 'border border-gray-200': isInsideLickGame
            }"
            >
                <h3 class="mb-3" v-if="gameTraduction">
                    {{ $t("game.wordOfXLetters", { lettersCount: gameTraduction.text.length }) }}&nbsp;/&nbsp;
                    <span
                        v-for="(type, idx) in word.type"
                        :key="type">
                        {{ idx > 0 ? ", " : "" }}{{ $t(`global.wordTypes.${type}`) }}
                    </span>
                </h3>
                <button
                    type="button"
                    class="appearance-none cursor-pointer"
                    @click.prevent.stop="playSound">
                    <Card
                        rounded
                        color="yellow">
                        <div class="p-3">
                            <Icons
                                icon="game-type-hear"
                                class="w-8 h-8 block text-white" />
                        </div>
                    </Card>
                </button>
                <audio
                    ref="audioTag"
                    class="hidden"
                    autoplay="false"
                    autostart="0">
                    <source
                        v-if="gameTraduction"
                        :src="gameTraduction.soundUrl"
                        :type="`${gameTraduction.soundUrl.includes('.wav') ? 'audio/wav' : 'audio/mpeg'}`" />
                </audio>
            </div>

            <div
                v-if="currentSection"
                class="h-1/2 md:h-full w-full md:w-3/4 rounded p-2 md:py-3 md:px-6"
                :class="{
                    'border border-gray-200': isInsideLickGame,
                    'bg-red': currentSection.isWrongAnswer,
                    'bg-green': currentSection.isCorrectAnswer,
                    'bg-white': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                }">
                <div class="w-full h-full flex flex-col justify-center items-center">
                    <div class="w-full h-12 md:h-24 flex flex-row justify-center items-end">
                        <button
                            v-for="(letter, index) in letters"
                            :key="letter.id"
                            type="button"
                            class="w-12 h-12 md:h-24 appearance-none cursor-pointer rounded flex flex-col justify-center items-center text-center text-white"
                            :class="{
                                h3: letter.isSpace,
                                h1: !letter.isSpace,
                                'text-white': currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                                'text-purple': !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                            }"
                            @click.prevent="letter.isToGuess && letter.guestLetterId ? removeLetter(index) : undefined">
                            <template v-if="!letter.isToGuess">
                                {{ letter.letter }}
                            </template>
                            <template v-else-if="letter.guestLetterId">
                                {{ getGuestLetter(letter.guestLetterId) }}
                            </template>
                            <template v-else>
                                <span class="flex flex-col justify-end items-center w-full h-full">
                                    <span
                                        class="block w-3/4 mx-auto border-dashed border-t-2 h-2"
                                        :class="{
                                            'border-white':
                                                currentSection.isWrongAnswer || currentSection.isCorrectAnswer,
                                            'border-black':
                                                !currentSection.isWrongAnswer && !currentSection.isCorrectAnswer
                                        }"></span>
                                </span>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full h-1/2 pt-3 md:pt-0">
            <div class="flex flex-row justify-center items-center space-x-3 h-full">
                <button
                    v-for="letter in toGuestLetters"
                    :key="letter.id"
                    type="button"
                    class="w-full h-full appearance-none cursor-pointer rounded flex flex-col justify-center items-center text-center text-purple"
                    :class="{
                        'opacity-50': guestLettersIsUsedMap[letter.id],
                        'bg-gray-100 border border-gray-200': isInsideLickGame,
                        'bg-white': !isInsideLickGame
                    }"
                    @click.prevent="toggleLetter(letter.id)">
                    <span
                        class="h1"
                        v-if="!letter.isSpace">
                        {{ letter.letter }}
                    </span>
                    <span
                        class="h3"
                        v-else>
                        {{ $t("global.space") }}
                    </span>
                </button>
            </div>
        </div>

        <div class="w-full flex flex-row justify-center items-center space-x-6">
            <button
                type="button"
                class="w-1/2 appearance-none cursor-pointer rounded py-6 flex flex-col justify-center items-center text-center bg-white text-purple h3"
                :class="{
                 'border border-gray-200': isInsideLickGame
                }"
                @click.prevent="skip">
                {{ $t("game.skipQuestion") }}
            </button>
            <button
                type="button"
                class="w-1/2 appearance-none cursor-pointer rounded py-6 flex flex-col justify-center items-center text-center h3"
                :class="{
                    'bg-gray-400 text-gray-200': lettersLeft.length > 0,
                    'bg-green text-white': lettersLeft.length === 0
                }"
                @click.prevent="sendAnswer">
                {{ $t("game.validate") }}
            </button>
        </div>
    </div>
</template>
<script>
import { FLAGS } from "services/countryFlags"
import { v4 } from "uuid"
import { shuffle } from "lodash-es"
import Card from "components/cards/Card.vue"
import Icons from "components/globals/Icons.vue"
import { GAME_TYPES } from "@globals/constants"

export default {
    name: "GameMissingLetters",
    components: { Icons, Card },
    props: {
        game: Object,
        wordGroup: Object,
        currentSection: Object
    },
    emits: ["answer"],
    data() {
        return {
            FLAGS,

            letters: []
        }
    },
    watch: {
        currentSection(nextValue, prevValue) {
            //reset to empty arr when we changed section
            if (nextValue && prevValue) {
                if (prevValue.sectionIdx !== nextValue.sectionIdx) {
                    this.letters = []
                }
            }

            if (nextValue.words.length === prevValue.words.length && !nextValue.isReal) return

            this.setLetters()
            this.playSound()
        }
    },
    created() {
        this.setLetters()
    },
    mounted() {
        this.playSound()
    },
    computed: {
        isInsideLickGame() {
            if (!this.game) return false
            if (!this.game.gameType) return false
            return this.game.gameType.slug === GAME_TYPES.LICK_3D_GAME
        },
        word() {
            if (!this.currentSection) return null
            if (this.currentSection.words.length === 0) return null
            return this.currentSection.words[0]
        },
        gameTraduction() {
            if (!this.word || !this.game) return null
            return this.word.traductions.find((t) => t.lang === this.game.lang)
        },
        currentAnswerText() {
            return this.letters.reduce((acc, l) => {
                if (!l.guestLetterId) {
                    acc += "_"
                    return acc
                }
                let guestLetter = this.letters.find((_l) => _l.id === l.guestLetterId)
                if (!guestLetter) {
                    acc += "_"
                    return acc
                }
                acc += guestLetter.letter
                return acc
            }, "")
        },
        lettersLeft() {
            return this.letters.filter((l) => !l.guestLetterId)
        },
        toGuestLetters() {
            return shuffle(this.letters.filter((l) => l.isToGuess))
        },
        guestLettersIsUsedMap() {
            return this.toGuestLetters.reduce((acc, letter) => {
                acc[letter.id] = this.letters.some((l) => l.guestLetterId === letter.id)
                return acc
            }, {})
        }
    },
    methods: {
        async playSound() {
            if (!this.$refs.audioTag) return
            this.$refs.audioTag.autoplay = false
            try {
                await this.$refs.audioTag.load()
                await this.$refs.audioTag.play()
                this.$refs.audioTag.playbackRate = 0.8
            } catch (e) {
            }
        },
        getGuestLetter(id) {
            if (!id) {
                return ""
            }

            let idx = this.letters.findIndex((l) => l.id === id)
            if (idx === -1) return ""

            return this.letters[idx].letter
        },
        setLetters() {
            if (!this.gameTraduction) return []
            let letters = this.gameTraduction.text.split("")

            //todo should depend on difficulty of the current game and grade of student...
            let idxNumber = 2//50%
            if (letters.length >= 8) idxNumber = 3


            this.letters = letters.reduce((acc, letter, index) => {
                let isSpace = letter === " "
                let isToGuess = false

                //dont guest space
                if (!isSpace) {
                    isToGuess = index % idxNumber === 0 //always remove half the letters
                }
                let id = v4()
                acc.push({
                    id: id,
                    letter: letter,
                    guestLetterId: isToGuess ? null : id,
                    isToGuess: isToGuess,
                    realIndex: index
                })
                return acc
            }, [])
        },
        removeLetter(idx) {
            this.letters[idx].guestLetterId = null
        },
        toggleLetter(id) {
            let idx = this.letters.findIndex((l) => l.guestLetterId === id)
            if (idx === -1) {
                this.addLetter(id)
                return
            }

            this.letters[idx].guestLetterId = null
        },
        addLetter(id) {
            let idx = this.letters.findIndex((l) => l.id === id)
            if (idx === -1) {
                return
            }
            let firstPositionAvailableIdx = this.letters.findIndex((l) => !l.guestLetterId)
            if (firstPositionAvailableIdx === -1) {
                return
            }
            this.letters[firstPositionAvailableIdx].guestLetterId = this.letters[idx].id
        },
        getGameTraduction(word) {
            if (!word || !this.game) return null
            return word.traductions.find((t) => t.lang === this.game.lang)
        },
        sendAnswer() {
            if (!this.currentSection) return
            if (!this.currentSection.isReal) return
            if (this.lettersLeft.length > 0) return
            if (!this.gameTraduction) return
            let isCorrectAnswer = this.gameTraduction.text.toLowerCase() === this.currentAnswerText.toLowerCase()
            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: isCorrectAnswer ? this.word.uuid : null, //pass null for wrong answer
                inputText: this.currentAnswerText
            })
        },
        skip() {
            if (!this.currentSection) return
            if (!this.currentSection.isReal) return
            this.$emit("answer", {
                wordUuid: this.word.uuid,
                answeredWordUuid: null,
                skipQuestion: true
            })
        }
    }
}
</script>
