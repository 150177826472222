<template>
    <div
        class="border-2 bg-gray-100 rounded-sm p-4 cursor-pointer space-y-3 "
        :class="{
                         'border-gray-200': !selected,
                         'border-green': selected,
                     }"
        @click.prevent="$emit('clicked', plan.handle)">
        <icons icon="check-circle" class="block"
               :class="{
                           'w-8 h-8 min-w-8' : size === 'normal',
                           'w-6 h-6 min-w-6': size === 'small',
                         'text-gray-200' : !selected,
                        'text-green' : selected,
                    }"
        />
        <h2 v-if="size === 'normal'">
            {{ plan.title }}
        </h2>
        <h4 v-else-if="size === 'small'">
            {{ plan.title }}
        </h4>

        <div v-if="annualPromoPrice">
            <div class="w-auto inline-block bg-yellow-200 text-black rounded-full py-1 px-2">
                <span class="text-10">
                    {{ annualPromoPrice }}$ {{ $t("billing.sale") }}
                </span>
            </div>
        </div>
        <div>
            <h4>
                {{ price }}$
                <span class="text-11 font-medium">
                    <span v-if="currentMode === 'monthly'"> {{ $t("billing.perMonth") }}</span>
                    <span v-else>{{ $t("billing.perYear") }}</span>
                </span>
            </h4>
            <span class="text-11 text-purple">
                {{ $t("billing.plusTax") }}
            </span>
        </div>
        <ul class="space-y-2" v-if="size === 'normal'">
            <li class="text-12 text-purple-40" v-for="item in plan.list" :key="item">
                {{ item }}
            </li>
        </ul>
    </div>
</template>
<script>
import Icons from "components/globals/Icons.vue"

export default {
    name: "PlanTypeCard",
    components: {
        Icons
    },
    emits: ["clicked"],
    props: {
        size: {
            type: String,
            default: "normal"
        },
        currentMode: {
            type: String,
            default: null
        },
        hasModes: {
            type: Boolean,
            default: true
        },
        plan: Object,
        selected: Boolean
    },
    computed: {
        price() {
            return this.plan.prices[this.currentMode]
        },
        annualPromoPrice() {
            if (!this.hasModes) {
                return false
            }
            if (this.currentMode !== "annual") {
                return false
            }
            const monthlyPriceInYear = this.plan.prices.monthly * 12
            return monthlyPriceInYear - this.plan.prices.annual
        }
    }
}
</script>
