<template>
    <UserHeader class="block lg:hidden mb-6">
        <h1>
            {{ $t("settings.title") }}
        </h1>
    </UserHeader>
    <div class="py-6 w-full h-full flex flex-col justify-start items-start gap-6">
        <TabbedButtons
            size="small"
            @click="onClickTab"
            :value="selectedTab"
            :options="tabs" />
        <Card class="w-full flex-1">
            <simplebar class="scroll-container">
                <div class="px-6">
                    <div v-show="selectedTab === 'profile'">
                        <form @submit.prevent="updateUser">
                            <h2>
                                {{ $t("settings.title") }}
                            </h2>


                            <div class="mt-6">
                                <UserForm
                                    ref="userForm"
                                    :errors="errors"
                                    :user="user" />
                            </div>
                            <div class="inline-block mt-6">
                                <CustomButton type="submit">
                                    {{ $t("form.btnSave") }}
                                </CustomButton>
                            </div>
                        </form>
                    </div>

                    <div v-show="selectedTab === 'references'">
                        <h2>
                            {{ $t("settings.references") }}
                        </h2>
                        <h3
                            class="pt-4"
                            v-if="user.refCode">
                            {{ $t("settings.refCode") }}:
                            <span>
                                #{{ user.refCode }}
                            </span>
                        </h3>

                        <p v-if="user.isParent">
                            {{ $t("settings.refCodeParentInfo") }}
                        </p>
                        <p v-else-if="user.isTeacher" class="-small text-purple md:w-1/2">
                            {{ $t("settings.refCodeTeacherInfo") }}
                        </p>

                        <!-- <p class="-small text-purple md:w-1/2">
                             {{ $t("settings.referencesInfo") }}
                         </p> -->
                        <p class="-smallest font-medium text-red mt-4" v-if="references.length === 0">
                            {{ $t("settings.noReferences") }}
                        </p>

                        <div class="mt-6" v-if="references.length > 0">
                            <div
                                class="w-full flex flex-row justify-start items-center space-x-1 py-2 border-b border-purple-10"
                            >
                                <div class="w-1/3">
                                    <p class="-smallest font-medium text-purple">
                                        {{ $t("settings.userName") }}
                                    </p>
                                </div>
                                <div class="w-1/3">
                                    <p class="-smallest font-medium text-purple">
                                        {{ $t("settings.connectedAt") }}
                                    </p>
                                </div>
                                <div class="w-1/3">

                                </div>

                            </div>

                            <div
                                class="flex flex-row justify-start items-center w-full py-2 space-x-1"
                                v-for="(reference, idx) in references"
                                :class="{
                                'border-t border-purple-10': idx !== 0
                            }"
                                :key="reference.id">
                                <div class="w-1/3">
                                    <p class="-small text-purple">
                                        {{ reference.useUser.name }}
                                    </p>
                                </div>
                                <div class="w-1/3">
                                    <p class="-small text-purple">
                                        {{ moment(reference.createdAt).format("LLL") }}
                                    </p>
                                </div>
                                <div class="w-1/3 flex flex-row justify-end items-center gap-2">
                                    <!-- Can only delete the one that was ref -->
                                    <!-- v-if="reference.fromUserId === user.id" -->
                                    <button

                                        class="btn h-full ml-2"
                                        type="button"
                                        @click.prevent="deleteReference(reference.id)">
                                        <icons
                                            class="h-6 w-auto inline-block text-purple"
                                            icon="trash" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </simplebar>
        </Card>

        <DeleteItemModal
            v-if="showDeleteReferenceModal"
            @delete="confirmDeleteReference"
            @close="showDeleteReferenceModal = false" />
    </div>
</template>

<script>
import * as types from "store/types"
import Card from "components/cards/Card"
import CustomButton from "components/globals/CustomButton"
import EventBus, { EVENTS } from "@/eventbus"
import Simplebar from "components/globals/Simplebar"
import UserHeader from "components/globals/UserHeader"
import UserForm from "components/forms/UserForm"
import cloneDeep from "lodash-es/cloneDeep"
import { tryAsyncFunc } from "@globals/helpers/async"
import ajax from "config/ajax"
import TabbedButtons from "components/globals/TabbedButtons.vue"
import StudentAvatar from "components/globals/StudentAvatar.vue"
import Icons from "components/globals/Icons.vue"
import DeleteItemModal from "components/modals/user/DeleteItemModal.vue"

export default {
    name: "UserSettings",
    components: {
        DeleteItemModal,
        Icons,
        StudentAvatar,
        TabbedButtons,
        UserForm,
        UserHeader,
        Simplebar,
        CustomButton,
        Card
    },
    data() {
        return {
            user: {},
            errors: {},
            references: [],

            refCode: "",

            selectedReferenceId: null,
            showDeleteReferenceModal: false,

            selectedTab: "profile",
            tabs: [
                {
                    value: "profile",
                    label: this.$t("settings.profile")
                },
                {
                    value: "references",
                    label: this.$t("settings.references")
                }
            ]
        }
    },
    created() {
        this.user = cloneDeep(this.$store.state.auth.user)
        this.getReferences()

        //show licenses count for teacher

        //todo allow user to change from parent to teacher, etc
    },
    computed: {},
    methods: {
        onClickTab(tab) {
            this.selectedTab = tab
            this.refCode = ""
            this.errors = {}//make sure form errors are reset
        },
        deleteReference(id) {
            this.selectedReferenceId = id
            this.showDeleteReferenceModal = true
        },
        async confirmDeleteReference() {
            this.showDeleteReferenceModal = false
            const id = this.selectedReferenceId
            this.selectedReferenceId = null
            const idx = this.references.findIndex(ref => ref.id === id)
            const [res, err] = await tryAsyncFunc(ajax.delete(`/user/me/references/${id}`))

            if (err) {
                this.errors = err.errors || {}
                return
            }
            this.errors = {}
            let newArr = [...this.references]
            newArr.splice(idx, 1)
            this.references = newArr
        },
        /*
        async createReference() {
            this.errors = {}
            if (!this.refCode) {
                this.errors.refCode = this.$t("validationErrors.refCodeDontExist")
                return
            }

            const [res, err] = await tryAsyncFunc(ajax.post(`/user/me/references`, {
                refCode: this.refCode
            }))
            if (err) {
                this.errors = err.errors || {}
                return
            }

            this.references.push(res.reference)
            this.refCode = ""
        }, */
        async getReferences() {
            const [res, err] = await tryAsyncFunc(ajax.get(`/user/me/references`))
            if (err) {
                return
            }
            this.references = res.references
        },
        updateUser() {
            this.errors = this.$refs.userForm.validate()
            if (Object.keys(this.errors).length > 0) {
                return
            }

            this.$store
                .dispatch(types.UPDATE_USER, this.user)
                .then((user) => {
                    this.user = cloneDeep(user)
                    EventBus.emit(EVENTS.SHOW_FLASH, {
                        title: this.$t("flash.success"),
                        message: this.$t("flash.accountSaved"),
                        type: "success"
                    })
                })
                .catch((res) => {
                    this.errors = res.errors
                })
        }
    }
}
</script>
