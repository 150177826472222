import * as types from "store/types"
import { msToTime, sleep } from "@globals/functions"
import ajax from "config/ajax"
import cloneDeep from "lodash-es/cloneDeep"
import EventBus, { EVENTS } from "@/eventbus"
import { GAME_TYPES } from "@globals/constants"
import { cleanGameCode } from "helpers/helpers"
import moment from "moment"
import orderBy from "lodash-es/orderBy"
import i18n from "config/i18n"
import { tryAsyncFunc } from "@globals/helpers/async"

const INIT_DATA = {
    GAME_TYPES,

    game: null,
    gameTypeSlug: "",
    wordGroup: null,
    timerMs: 0,
    totalTimerMs: 0,
    showQuittingModal: false,

    currentSection: null,

    //must match createGameData in backend
    gameData: {
        game: null,
        studentGames: [],
        hasLeftStudentGames: [],
        logs: {
            allStudentIdsThatJoinedGame: []
        },
        extraData: {},
        sections: [],
        sectionsCount: 0,
        sectionsDoneCount: 0,
        maxSecondsToAnswer: 15,
        isFinish: false,
        totalGameTimeMs: 0,
        createdAtMs: null,
        updatedAtMs: null,
        isStarted: false,
        startedAtMs: null,
        endedAtMs: null
    },

    isLoading: true,
    isSendingAnswer: false
}

const gameMixin = {
    data() {
        return {
            ...INIT_DATA
        }
    },
    beforeUnmount() {
        clearInterval(this.timerIntervalId)
        clearInterval(this.totalTimerIntervalId)
    },
    created() {
        this._refAudioGoodSound = document.querySelector("#global-sounds__good-sound")
        this._refAudioBadSound = document.querySelector("#global-sounds__bad-sound")

        //the sound is too loud !
        if (this._refAudioGoodSound) {
            this._refAudioGoodSound.volume = 0.25
        }
        if (this._refAudioBadSound) {
            this._refAudioBadSound.volume = 0.25
        }
    },
    computed: {
        isLiveGame() {
            //use the router and not the gameType so we knows right from the start
            return this.$route.path.includes("/live/")
        },
        timer() {
            if (this.gameTypeSlug === GAME_TYPES.LICK_3D_GAME) {
                return msToTime(this.totalTimerMs)
            }

            return msToTime(this.timerMs)
        },
        cleanCode() {
            if (!this.game) return ""
            if (!this.game.code) return ""
            return cleanGameCode(this.game.code)
        },
        gameType() {
            let arr = []
            if (this.$store.getters.isStudentLogin) {
                arr = this.$store.state.studentData.global.gameTypes
            }
            if (this.$store.getters.isUserLogin) {
                arr = this.$store.state.userData.global.gameTypes
            }
            if (this.$store.getters.isAnonymousLogin) {
                arr = this.$store.state.anonymousData.global.gameTypes
            }

            return arr.find((g) => g.slug === this.gameTypeSlug)
        },
        gameTraduction() {
            if (!this.wordGroup || !this.game) return null
            return this.wordGroup.traductions.find((t) => t.lang === this.game.lang)
        },
        traduction() {
            if (!this.wordGroup) return null
            if (this.$store.getters.isUserLogin) {
                return this.$store.getters.getUserTraduction(this.wordGroup)
            }

            if (this.$store.getters.isStudentLogin) {
                return this.$store.getters.getStudentTraduction(this.wordGroup)
            }
            if (this.$store.getters.isAnonymousLogin) {
                return this.$store.getters.getAnonymousTraduction(this.wordGroup)
            }
            return null
        }
    },
    methods: {
        async init() {
            //wrong url
            this.gameTypeSlug = this.$route.params.gameType
            if (!Object.values(this.GAME_TYPES).includes(this.gameTypeSlug)) {
                this.$router.replace("/")
                return
            }
        },
        onGoBack() {
            if (this.$store.getters.isStudentLogin) {
                //if finish, dont stop modal
                if (this.gameData.isFinish) {
                    this.leaveGame()
                    return
                }
                this.showQuittingModal = true
            }

            if (this.$store.getters.isUserLogin) {
                this.leaveGame()
            }

            if (this.$store.getters.isAnonymousLogin) {
                this.leaveGame()
            }
        },
        stopTimer() {
            //clear
            clearInterval(this.timerIntervalId)
        },
        startTimer() {
            this.stopTimer()
            this.timerMs = 0 //init

            this.timerIntervalId = setInterval(() => {
                this.timerMs += 10
            }, 10)
        },
        stopTotalTimer() {
            clearInterval(this.totalTimerIntervalId)
        },
        startTotalTimer() {
            this.stopTotalTimer()

            this.totalTimerIntervalId = setInterval(() => {
                this.totalTimerMs += 100
            }, 100)
        },
        resetData() {
            //replace all data
            Object.keys(INIT_DATA).forEach((key) => (this[key] = INIT_DATA[key]))
        },
        playAnswerSound(isCorrectAnswer) {
            if (isCorrectAnswer && this._refAudioGoodSound) {
                try {
                    this._refAudioGoodSound.pause()
                    this._refAudioGoodSound.currentTime = 0
                    this._refAudioGoodSound.play()
                } catch (e) {
                }
            }

            if (!isCorrectAnswer && this._refAudioBadSound) {
                try {
                    this._refAudioBadSound.pause()
                    this._refAudioBadSound.currentTime = 0
                    this._refAudioBadSound.play()
                } catch (e) {
                }
            }
        },
        async sendAnswer(args) {
            if (this.isSendingAnswer) return
            this.isSendingAnswer = true

            let isSimpleAnswer = !!args.wordUuid
            let isMultipleAnswer = (args.wordsUuid || []).length > 0

            //if answeredWordUuid is not defined, then its wrong answer
            let isCorrectAnswer = false

            if (isSimpleAnswer) {
                isCorrectAnswer = args.answeredWordUuid ? args.wordUuid === args.answeredWordUuid : false
            }

            if (isMultipleAnswer) {
                isCorrectAnswer = args.wordsUuid.join("|") === args.answeredWordsUuid.join("|")
            }

            let wordsUuid = args.wordsUuid ? args.wordsUuid : [args.wordUuid]
            let answeredWordsUuid = args.answeredWordsUuid ? args.answeredWordsUuid : [args.answeredWordUuid]


            this.stopTimer()

            //play sound before going to server
            if (this.gameTypeSlug !== GAME_TYPES.CONTEST) {
                this.playAnswerSound(isCorrectAnswer)
            }

            let oldStudentGameData = cloneDeep(this.studentGameData)
            let oldCurrentSection = cloneDeep(this.currentSection)

            let url = ""
            if (this.$store.getters.isStudentLogin) {
                url = `/student/student-games/${this.studentGameData.studentGame.uuid}/answer`
            }
            if (this.$store.getters.isAnonymousLogin) {
                url = `/anonymous/games/${this.game.uuid}/answer`
            }

            let res = null
            try {
                let idx = 0
                for (let wordUuid of wordsUuid) {
                    let answerUuid = answeredWordsUuid[idx]
                    res = await ajax.put(url, {
                        answeredWordUuid: answerUuid,
                        wordUuid: wordUuid,
                        skipQuestion: args.skipQuestion,
                        timeToAnswerMs: this.timerMs,
                        inputText: args.inputText ? args.inputText : null
                    })
                    idx++
                }
            } catch (e) {
                if (this.$store.getters.isStudentLogin) {
                    this.$router.push(`/student/list/${this.wordGroup.uuid}`)
                }
                if (this.$store.getters.isAnonymousLogin) {
                    this.$router.push(`/anonymous/list/${this.wordGroup.uuid}`)
                }

                EventBus.emit(EVENTS.SHOW_FLASH, {
                    title: i18n.global.t("flash.error"),
                    message: i18n.global.t("flash.errorOccurred"),
                    type: "error"
                })
                this.isSendingAnswer = false
                return
            }

            if (!res) return

            const { student, gameData } = res

            this.gameData = {
                ...gameData,
                isFinish: false //always to false before the 1.5seconds
            }

            //wait for computed
            await this.$nextTick()

            //big issue since now studentGameData is null
            if (!this.studentGameData) {
                if (this.$store.getters.isStudentLogin) {
                    this.$router.push(this.wordGroup ? `/student/list/${this.wordGroup.uuid}` : "/student")
                }
                if (this.$store.getters.isAnonymousLogin) {
                    this.$router.push(this.wordGroup ? `/anonymous/list/${this.wordGroup.uuid}` : "/anonymous")
                }

                EventBus.emit(EVENTS.SHOW_FLASH, {
                    title: i18n.global.t("flash.error"),
                    message: i18n.global.t("flash.errorOccurred"),
                    type: "error"
                })
                return
            }

            //wait for other player to answer
            if (this.gameTypeSlug === GAME_TYPES.CONTEST) {
                let maxTime = 30000 //limit MS for testing
                let startTime = 0

                let everyStudentsHasAnswer
                do {
                    await this.$nextTick()
                    everyStudentsHasAnswer = this.gameData.studentGames.every(
                        (sG) => sG && sG.sectionsDoneCount >= this.studentGameData.sectionsDoneCount
                    )

                    if (!everyStudentsHasAnswer) {
                        await sleep(250)
                        startTime += 250
                        if (startTime > maxTime) {
                            break
                        }
                    }
                } while (!everyStudentsHasAnswer)
            }

            //set with result
            this.currentSection = {
                ...oldCurrentSection,
                isReal: false,
                sectionIdx: oldCurrentSection.sectionIdx,
                words: oldCurrentSection.words,
                isWrongAnswer: !isCorrectAnswer,
                isCorrectAnswer: isCorrectAnswer,
                answers: oldCurrentSection.answers.map((answer) => {

                    if (isSimpleAnswer) {
                        //add result, compare from last to new data
                        if (answer.uuid === args.answeredWordUuid) {
                            answer.wasGoodAnswer = answer.uuid === args.wordUuid
                            answer.wasWrongAnswer = answer.uuid !== args.wordUuid
                        }
                        if (answer.uuid === args.wordUuid) {
                            answer.wasGoodAnswer = true
                        }
                    }

                    if (isMultipleAnswer) {

                        answer.wasGoodAnswer = false
                        answer.wasWrongAnswer = true

                        //good answer mean the correct order of words
                        if (args.answeredWordsUuid.includes(answer.uuid)) {
                            let idx = args.wordsUuid.indexOf(answer.uuid)
                            let answerIdx = args.answeredWordsUuid.indexOf(answer.uuid)
                            answer.wasGoodAnswer = idx === answerIdx
                            answer.wasWrongAnswer = idx !== answerIdx
                        }
                    }
                    return answer
                })
            }

            let waitTime = 1000 //1 seconds

            //2.5 seconds for hangman to check for word
            if (this.gameTypeSlug === GAME_TYPES.HANGMAN) {
                waitTime = 2500
            }

            //to check for words
            if (this.gameTypeSlug === GAME_TYPES.BATTLE) {
                waitTime = 2000
            }

            //so we can see
            if (this.gameTypeSlug === GAME_TYPES.WRITE) {
                waitTime = 2000
            }

            //2 seconds for context
            if (this.gameTypeSlug === GAME_TYPES.CONTEST) {
                waitTime = 2000

                //play audio
                this.playAnswerSound(isCorrectAnswer)
            }

            //allow user check result for 1 seconds
            if (waitTime > 0) {
                await sleep(waitTime)
            }

            //reset
            this.timerMs = 0 //set to 0
            this.isSendingAnswer = false

            let finalGameData = gameData

            //update all now by grabing the freshest game data available
            if (this.gameDatasReceivedWhileAnswering.length > 0) {
                finalGameData = orderBy(
                    [...this.gameDatasReceivedWhileAnswering, gameData],
                    ["updatedAtMs"],
                    ["desc"]
                )[0]
                this.gameDatasReceivedWhileAnswering = [] //reset array
            }

            //update game data
            this.gameData = finalGameData

            //wait for computed
            await this.$nextTick()

            //big issue since now studentGameData is null
            if (!this.studentGameData) {
                if (this.$store.getters.isStudentLogin) {
                    this.$router.push(this.wordGroup ? `/student/list/${this.wordGroup.uuid}` : "/student")
                }
                if (this.$store.getters.isAnonymousLogin) {
                    this.$router.push(this.wordGroup ? `/anonymous/list/${this.wordGroup.uuid}` : "/anonymous")
                }

                EventBus.emit(EVENTS.SHOW_FLASH, {
                    title: i18n.global.t("flash.error"),
                    message: i18n.global.t("flash.errorOccurred"),
                    type: "error"
                })
                return
            }

            //update student with new points
            if (this.$store.getters.isStudentLogin) {
                if (this.studentGameData.isFinish) {
                    this.stopTotalTimer()
                    this.$store.commit(types.UPDATE_STUDENT, student)
                    return
                }
            }

            if (this.gameData.isFinish) {
                this.stopTotalTimer()
                return
            }

            //go to next section
            this.currentSection = {
                isReal: true,
                ...this.studentGameData.sections[0]
            }

            this.startTimer()
        },
        async restart() {
            this.resetData()
            if (this.$store.getters.isAnonymousLogin) {
                if (this.hasNoGameLeft()) {
                    return
                }
            }
            await this.init()
            await this.initGame()
        },
        async duplicateLiveGame() {
            let res = await ajax.post(`/user/live-games/${this.game.uuid}/duplicate`)

            let { success, game } = res

            if (!success) {
                //todo handle error
                return
            }

            this.$router.push("/user")
            await sleep(300)
            this.$router.push(`/user/live/${game.uuid}/${this.gameTypeSlug}`)
        }
    }
}

export const studentGameMixin = {
    mixins: [gameMixin],
    data() {
        return {
            gameDatasReceivedWhileAnswering: []
        }
    },
    computed: {
        studentGameData() {
            if (!this.gameData) return null
            return this.gameData.studentGames.find((sG) => sG.student && sG.student.id === this.$store.state.auth.student.id) || null
        },
        showResult() {
            if (!this.gameType || !this.gameData) return false
            if (this.gameType.slug === GAME_TYPES.CONTEST) {
                return this.gameData.isFinish
            }

            return this.studentGameData.isFinish || this.gameData.isFinish
        }
    },
    beforeUnmount() {
        if (!this.gameData.isFinish && this.gameData.game) {
            //todo maybe juste live game ?
            ajax.post(`/student/games/${this.gameData.game.uuid}/leave`)
        }
    },
    methods: {
        async fetchWordGroup(wordGroupId) {
            this.wordGroup = this.$store.state.studentData.wordGroups.find((wG) => wG.id === wordGroupId) || null
            if (!this.wordGroup) {
                this.wordGroup = await this.$store.dispatch(types.STUDENT_DATA.GET_WORD_GROUP_BY_ID, wordGroupId)

                //word group don't exist
                if (!this.wordGroup) {
                    this.$router.replace("/student/live-battle")
                    return false
                }
            }
            return this.wordGroup
        },
        leaveGame() {
            //tell server we leave game
            if (!this.gameData.isFinish && this.gameData.game) {
                ajax.post(`/student/games/${this.gameData.game.uuid}/leave`)
            }
            //go back to list or live game
            if (this.isLiveGame) {
                this.$router.push(`/student/live-battle`)

                //refetch top 10
                this.$store.dispatch(types.STUDENT_DATA.GET_LAST_HOUR_LIVE_BATTLE_SCOREBOARD)
            } else {
                this.$router.push(this.wordGroup ? `/student/list/${this.wordGroup.uuid}` : "/student")
            }
        }
    }
}

export const userGameMixin = {
    mixins: [gameMixin],
    watch: {
        "gameData.startedAtMs"(nextValue, prevValue) {
            //offset
            if (nextValue && !prevValue) {
                this.timerMs = moment.utc().valueOf() - nextValue //init with time
            }
        }
    },
    methods: {
        terminateGame() {
            ajax.post(`/user/live-games/${this.game.uuid}/terminate`)
            //response will be handle with websocket
        },
        leaveGame() {
            //go back to list or live battle
            this.$router.push(`/user`)
        },
        async fetchWordGroup(wordGroupId) {
            this.wordGroup = this.$store.state.userData.wordGroups.find((wG) => wG.id === wordGroupId) || null
            if (!this.wordGroup) {
                this.wordGroup = await this.$store.dispatch(types.USER_DATA.GET_WORD_GROUP_BY_ID, wordGroupId)

                //word group don't exist
                if (!this.wordGroup) {
                    this.$router.replace("/user")
                    return false
                }
            }
            return this.wordGroup
        }
    }
}

export const anonymousGameMixin = {
    mixins: [gameMixin],
    data() {
        return {
            gameDatasReceivedWhileAnswering: []
        }
    },
    computed: {
        studentGameData() {
            if (!this.gameData) return null
            return (
                this.gameData.studentGames.find((sG) => sG.student && sG.student.id === this.$store.state.auth.anonymous.id) || null
            )
        },
        showResult() {
            if (!this.gameType || !this.gameData) return false
            if (this.gameType.slug === GAME_TYPES.CONTEST) {
                return this.gameData.isFinish
            }

            return this.studentGameData.isFinish || this.gameData.isFinish
        }
    },
    methods: {
        hasNoGameLeft() {
            this.$store.commit(types.ANONYMOUS_DATA.ANONYMOUS_PLAYED_GAME)
            let hasNoGameLeft = this.$store.state.anonymousData.gameLeft <= 0
            if (hasNoGameLeft) {
                this.$router.push(`/anonymous`)
            }
            return hasNoGameLeft
        },
        leaveGame() {
            //tell server we leave game
            if (!this.gameData.isFinish && this.gameData.game) {
                tryAsyncFunc(ajax.post(`/anonymous/games/${this.gameData.game.uuid}/leave`))
            }
            //go back to list or live game
            if (this.isLiveGame) {
                this.$router.push(`/anonymous`)
            } else {
                this.$router.push(`/anonymous/list/${this.wordGroup.uuid}`)
            }
        },
        async fetchWordGroup(id) {
            this.wordGroup = this.$store.state.anonymousData.wordGroups.find((wG) => wG.id === id) || null
            if (!this.wordGroup) {
                this.wordGroup = await this.$store.dispatch(types.ANONYMOUS_DATA.GET_WORD_GROUP_BY_ID, id)
                if (!this.wordGroup) {
                    this.$router.replace(`/anonymous`)
                    return false
                }
            }
            return this.wordGroup
        }
    },
    beforeUnmount() {
        if (!this.gameData.isFinish && this.gameData.game) {
            //todo maybe juste live game ?
            tryAsyncFunc(ajax.post(`/anonymous/games/${this.gameData.game.uuid}/leave`))
        }
    }
}
