export default {
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allo"])},
    "myProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma progression"])},
    "seeMyProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir mon progrès"])},
    "lastProductPurchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier produits utilisés"])},
    "seeMyPurchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir mes achats"])},
    "listOfTheWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voici la liste de la semaine"])}
  },
  "licenses": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
    "licenseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations des licences"])},
    "licenseUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation"])},
    "licensesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licence restante"])},
    "noOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun"])},
    "btnBuyLicenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter des licences"])},
    "addTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un professeur"])}
  },
  "billing": {
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d'économie"])},
    "chooseTeacherPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un forfait professeur"])},
    "noTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez aucun professeur pour l'instant. Vous pouvez le créer ou l'invitez à créer son compte par lui-même."])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
    "teachersInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations des professeurs"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
    "studentsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'étudiants"])},
    "needLicensePopup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement requis"])},
    "teacherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professeur"])},
    "plansInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information des abonnements"])},
    "planInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de l'abonnement"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "noLicenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune licences pour l'instant"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "typeLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat de licence"])},
    "typeRenewLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouvellement de licence"])},
    "typeSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "childrenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'enfant"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "simplePriceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAD"])},
    "priceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ an + taxes"])},
    "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ mois"])},
    "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/ an"])},
    "plusTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ taxes"])},
    "subscriptionCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulée"])},
    "btnCancelSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "btnActiveSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactivé"])},
    "noStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez ajouter des enfants"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de paiement"])},
    "noPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun mode de paiement pour l'instant"])},
    "changePaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer votre mode de paiement"])},
    "addPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter votre mode de paiement"])},
    "noPaymentMethodTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter votre mode de paiement pour acheter votre abonnement"])},
    "noPaymentMethodParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter votre mode de paiement pour acheter des abonnements"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "noInvoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transactions pour l'instant"])},
    "userSubscriptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer un abonnement vous permet de créer des étudiants et les permettre de jouer."])},
    "hasSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À déjà un abonnement"])},
    "noSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun abonnement"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheteur"])},
    "planName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du plan"])},
    "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
    "renewDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du renouvellement"])},
    "renewPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix du renouvellement"])},
    "limitedStudents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre abonnement vous permet de créer ", _interpolate(_named("count")), " étudiants."])},
    "unlimitedStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre abonnement vous permet de créer le nombre d'étudiants que vous souhaitez."])},
    "limitedChildrens": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre abonnement vous permet de créer ", _interpolate(_named("count")), " enfants."])},
    "unlimitedChildrens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre abonnement vous permet de créer le nombre d'enfants que vous souhaitez."])},
    "btnCancelSubscriptionComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'abonnement"])},
    "btnActivateSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter l'abonnement"])}
  },
  "tools": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils"])},
    "use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser"])},
    "dictation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une dictée"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permets la création de dictées automatique avec notre intelligence artificielle"])},
      "wordsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de mots"])},
      "words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mots"])},
      "oneWordPerLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des mots supplémentaire içi. Un mot par ligne"])},
      "regenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régénérer la dictée"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer la dictée"])},
      "warningMes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce texte a été généré avec notre intelligence artificielle. Veuillez nous excuser s'il produit des résultats non concluants. Veuillez valider le texte avant de le distribuer."])},
      "hideWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher les mots"])},
      "showWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer les mots"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier la dictée"])},
      "classroomExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner une classe permet d'ajouter les mots les plus manqué de cette classe dans la dictée."])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La génération de dictée peut prendre jusqu'à une minute."])},
      "willBeGenerateHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dictée sera générée ici…"])},
      "wordsUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots utilisés"])},
      "copyDictationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dictée copiée avec succès"])}
    }
  },
  "list": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes"])},
    "titleCustom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes perso"])},
    "customList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de mots personnalisée"])},
    "listGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensemble de liste"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "wordsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de mots"])},
    "isCurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de la semaine"])},
    "see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir cette liste"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les listes"])},
    "words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots"])},
    "noWordGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune liste de mots. Les étudiants associés à cet ensemble vont voir les listes préfabriqués."])},
    "noListGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez créer un ensemble de liste"])},
    "searchWordsPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un mot..."])},
    "searchWordGroupsPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "searchTeachersPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un professeur..."])},
    "noTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun professeur pour votre recherche. Vous pouvez le créer ou l'invitez à créer son compte par lui-même."])},
    "externalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code unique"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["année"])}
  },
  "play": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouer"])},
    "liveGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partie en ligne"])},
    "joinLiveGameWithCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Joindre avec un code"])},
    "joinLiveGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre une partie en ligne"])},
    "practiceWithListGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratiquez vos liste de mots"])},
    "finishTheSentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complète<br/>la phrase"])},
    "placeWordsInOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mets les<br/>mots en<br />ordre alphabétique"])},
    "writeWordsCorrectlyBySound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écris les mots<br/>correctement (Audio)"])},
    "writeWordCorrectlyBySound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écris le mot<br/>correctement (Audio)"])},
    "createLiveGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une partie en ligne"])},
    "noCustomList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune liste de mot personnalisées pour cette classe"])},
    "ensureCustomListIsConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous de connecter votre classe avec votre ensemble de liste"])},
    "typeCustom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnalisée"])},
    "typeList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préfabriqué"])},
    "league": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligue"])},
    "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe"])},
    "random": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aléatoire"])},
    "btnJoinGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre la partie"])}
  },
  "classroom": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe"])},
    "btnCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer la classe"])}
  },
  "settings": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "refCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de référence"])},
    "refCodeParentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager ce code avec vos amis"])},
    "refCodeTeacherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager ce code avec vos collègues"])},
    "simpleTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de compte"])},
    "shortcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de connexion"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professeur"])},
    "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent"])},
    "emailNotVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel non vérifié"])},
    "references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Références"])},
    "referencesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ajouter des références pour les étudiants et les parents. Ces références seront utilisées pour créer des codes de connexion uniques pour chaque étudiant et parent."])},
    "noReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez aucune références pour l'instant"])},
    "connectedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
  },
  "students": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étudiants"])},
    "titleParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfants"])},
    "myClassrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes classes"])},
    "myGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes groupes"])},
    "noClassroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez créer une classe avant d'ajouter des étudiants"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "isConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté"])},
    "difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "seeThePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le PDF"])},
    "seeStudentProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le progrès"])},
    "myFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma famille"])},
    "noStudentsFromTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun étudiant dans cette classe"])},
    "noStudentsFromParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez créer des enfants"])},
    "noListGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun ensemble de liste"])},
    "listGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple"])},
    "multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple"])},
    "addStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un étudiant"])},
    "addChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un enfant"])}
  },
  "student": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étudiant"])},
    "titleParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfant"])},
    "btnTeacherCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer l'étudiant"])},
    "btnParentCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer l'enfant"])}
  },
  "shop": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasin"])},
    "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par"])},
    "viewProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir ce produit"])},
    "productCurrentlyInUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipée"])},
    "useProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisé"])},
    "productAlreadyBrought": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achetée"])},
    "buyProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
    "notEnoughToBuyProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas assez de points"])},
    "unlockProductAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Niveau ", _interpolate(_named("level"))])}
  },
  "inventory": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achats"])},
    "noProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produits trouvées"])}
  },
  "friends": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amis"])},
    "findALeague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez une ligue"])},
    "noLeagues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune ligue trouvées"])},
    "leagueMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
    "leagueInvitationPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "joinLeague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre"])},
    "alreadyInLeague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deja dans la ligue"])},
    "searchLeaguePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag de la ligue"])}
  },
  "game": {
    "moneyLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argent limite"])},
    "timeLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps limite"])},
    "endGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de partie"])},
    "inGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partie en cours"])},
    "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin"])},
    "waitingForAllPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente que tous les joueurs finissent leur partie"])},
    "waitingPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente des autres joueurs"])},
    "timeLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps restant"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "btnFinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer"])},
    "btnQuitGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter la partie"])},
    "btnTerminateGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finir la partie"])},
    "btnStartGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])},
    "btnReplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejouer"])},
    "btnCancelGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la partie"])},
    "canOnlyBeStartByCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La partie sera commencée par le créateur"])},
    "players": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Joueurs (", _interpolate(_named("count")), ")"])},
    "startIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commence dans"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])},
    "congrats": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bonne partie ", _interpolate(_named("name")), "!"])},
    "myScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon résultat"])},
    "perfectGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partie parfaite !"])},
    "missedWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots manqués"])},
    "connectedPlayers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joueurs connectés"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglages"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager"])},
    "modification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification"])},
    "changeGameCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le code de partie"])},
    "onComputer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour rejoindre cette partie"])},
    "changeMaxSecondsToAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le temps de réponse"])},
    "wordsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le nombre de questions"])},
    "changeWordGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer la liste de mots"])},
    "changeOptionsLickGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer les options de partie"])},
    "shareWithStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinon partagez à vos étudiants"])},
    "goTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller au"])},
    "copyGameLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien de la partie"])},
    "1st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1er"])},
    "2nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2e"])},
    "3rd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3e"])},
    "startedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
    "gameTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de partie"])},
    "wordGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de mots"])},
    "gameType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de jeu"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "isPerfectGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partie parfaite"])},
    "isClassroomGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partie de classe"])},
    "gameNotFinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non complété"])},
    "noGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune parties jouées"])},
    "totalScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score accumulé"])},
    "bestPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleure position"])},
    "nmbOfPerfectGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de partie parfaite"])},
    "nmbOfGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de partie"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat"])},
    "seeResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le résultat"])},
    "cantSeeResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat indisponible"])},
    "bonusQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question bonus !"])},
    "2xPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2x points"])},
    "skipQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauter la question"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "wordOfXLetters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("lettersCount")), " lettres"])},
    "hearTheWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendre le mot"])},
    "tryLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tryLeft")), " essais restants"])}
  },
  "games": {
    "lick": {
      "bagIsFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre sac est plein. Veuillez vendre vos mouches."])},
      "needToAnswerQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez répondre à des questions en premier."])},
      "noBugsToSell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez aucune mouche à vendre."])},
      "currentBag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sac présent"])},
      "notEnoughMoneyToBuyBag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas l'argent pour acheter ce sac"])},
      "notEnoughMoneyToBuyLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas l'argent pour acheter ce niveau"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
      "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacité"])},
      "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
      "lick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lécher"])},
      "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendre"])},
      "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder"])},
      "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer ici"])},
      "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
      "clickOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cliquer sur"])},
      "for2Seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pendant 2 secondes"])},
      "lookingForBugs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la recherche de mouche..."])},
      "lickAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lécher encore"])},
      "lickNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lécher n'est plus disponible"])},
      "bagFull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre sac est plein. Veuillez vendre vos mouches."])},
      "answerQuestionsFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez répondre à des questions en premier."])},
      "selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente en cours...."])},
      "sellingResult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vendu ", _interpolate(_named("count")), " mouches pour"])},
      "changingLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de niveau en cours..."])}
    }
  },
  "scoreboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau des scores"])},
    "seeLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir moins"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
    "mySchool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon école"])},
    "myLeagues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes ligues"])},
    "lastHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau des scores"])},
    "noPlayersLastHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun joueurs n'a joué dans la dernière heure"])},
    "podium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podium"])},
    "moreStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'étudiants disponible..."])},
    "showAllStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer tous les étudiants"])},
    "hideAllStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher les étudiants"])}
  },
  "notifications": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "noNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune nouvelle notifications"])},
    "gameInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partie"])},
    "leagueInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligue"])},
    "leagueRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligue"])},
    "receivedGameInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez reçu une invitation de partie en ligne"])},
    "receivedLeagueInvitation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes invité a rejoindre la ligue ", _interpolate(_named("name"))])},
    "receivedLeagueRequest": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("studentName")), " aimerais rejoindre votre ligue ", _interpolate(_named("name"))])},
    "btnJoinGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre la partie"])},
    "btnAcceptRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
    "btnRejectRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
    "statusAccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepté"])},
    "statusRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusé"])},
    "gameIsFinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partie terminée"])}
  },
  "stats": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progression"])},
    "myProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma progression"])},
    "myLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes statistiques"])},
    "noStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez faire des parties pour voir plus de mots"])},
    "noStatsFromTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet élève n'a joué aucune partie"])},
    "noStatsFromParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet enfant n'a joué aucune partie"])},
    "wordsLearnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots appris"])},
    "wordsMastered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots maitrisés"])},
    "wordsEasy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots faciles"])},
    "wordsUnseen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots non-joués"])},
    "wordsDifficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots difficiles"])},
    "successRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de réussite"])},
    "badResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
    "goodResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réussite"])},
    "allWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes mots"])},
    "nextLevelIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochain niveau dans"])},
    "myGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes parties"])},
    "totalTimePlayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps joué sur la plateforme"])},
    "totalGamesPlayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parties jouées"])},
    "totalPodiumCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de podiums"])},
    "totalPerfectGameCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parties parfaites"])},
    "totalScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score accumulé"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parties"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élèves"])},
    "wordGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de mots"])},
    "seeWordStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les mots"])},
    "hideWordStats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher les mots"])},
    "tries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essai"])},
    "word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
    "filterByDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par date"])},
    "noWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun mots"])}
  },
  "auth": {
    "onlyChargedWhenTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne serez facturé que lorsque vous aurez ajouté des professeurs."])},
    "createStudents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer vos étudiants"])},
    "createChildrens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer vos enfants"])},
    "createFirstClassroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer votre première classe"])},
    "choosePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un forfait"])},
    "studentTakeNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez prendre ces informations en note avant de vous connecter"])},
    "playWithoutAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouer sans compte"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et Conditions"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
    "btnLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "btnForgotPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
    "btnCreateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer mon compte"])},
    "onlyWorkForTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonctionnalité fonctionne seulement pour les professeurs qui donnent un code de classe à leurs étudiants."])},
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étudiant"])},
    "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfant"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professeur"])},
    "schoolManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur d'école"])},
    "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent"])},
    "loginIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "changePwdIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mon mot de passe"])},
    "forgotPwdIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
    "forgotPwdSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre boite courriel"])},
    "forgotPwdParentSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier la boite courriel de vos parents"])},
    "btnChangePwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mon mot de passe"])},
    "btnForgotPwdRestart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommencer ?"])},
    "btnLoginWithCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec un code"])},
    "btnLoginWithUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter avec un nom d'utilisateur"])},
    "registerIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription à la plateforme"])},
    "registerDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis..."])},
    "continueWithParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je continue avec un parent"])},
    "goToStudentRegisterWithClassroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai un code de classe"])},
    "studentIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu veux découvrir le fabuleux monde de adimo?"])},
    "studentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande à un parent de venir t'aider à te créer un compte... c'est un peu compliqué et ennuyant, et les parents aiment ça les choses compliquées et ennuyantes!"])}
  },
  "help": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone Entraide"])}
  },
  "global": {
    "skipForNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauter pour l'instant"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pts"])},
    "noName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun nom"])},
    "nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun"])},
    "noEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun courriel"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niv."])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "noClassrooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune classe"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vous"])},
    "noSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas spécifier"])},
    "noneFr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "btnDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "dndHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glissez-déposez le mot ici"])},
    "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secondes"])},
    "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questions"])},
    "words": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mots"])},
    "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
    "space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouer"])},
    "help": {
      "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendre le son"])},
      "switchLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de langue"])},
      "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progression"])},
      "seeMoreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])}
    },
    "plans": {
      "modes": {
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuel"])},
        "annual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuel"])}
      },
      "planTypes": {
        "PARENT_STANDARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait Découverte"])},
        "PARENT_PREMIUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait Progression"])},
        "TEACHER_STANDARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait Professeur"])},
        "TEACHER_ON_DEMAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait sur demande"])},
        "PARENT_STANDARD_MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait Découverte Mensuel"])},
        "PARENT_STANDARD_ANNUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait Découverte Annuel"])},
        "PARENT_PREMIUM_MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait Progression Mensuel"])},
        "PARENT_PREMIUM_ANNUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait Progression Annuel"])},
        "TEACHER_STANDARD_ANNUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait Professeur Annuel"])},
        "TEACHER_ON_DEMAND_ANNUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait sur demande Annuel"])}
      },
      "planHandles": {
        "TEACHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professeur"])},
        "PARENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent"])},
        "PARENT_STANDARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait Découverte"])},
        "PARENT_PREMIUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait Progression"])},
        "TEACHER_STANDARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait Professeur"])},
        "TEACHER_ON_DEMAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forfait sur demande"])}
      }
    },
    "langs": {
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Português"])}
    },
    "wordTypes": {
      "verb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbe"])},
      "noun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "noun-plu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom pluriel"])},
      "noun-m-plu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom masculin pluriel"])},
      "noun-f-plu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom féminin pluriel"])},
      "noun-m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom masculin"])},
      "noun-f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom féminin"])},
      "adv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adverbe"])},
      "adj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjectif"])},
      "prep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préposition"])},
      "det": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déterminant"])},
      "pron": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronom"])},
      "conj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conjonction"])},
      "letter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettre"])},
      "invariable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invariable"])}
    },
    "productCategories": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
      "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
      "badge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge"])},
      "main-background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fond d'écran"])},
      "background": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fond"])}
    },
    "genders": {
      "m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garçon"])},
      "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fille"])},
      "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])}
    },
    "gameTypes": {
      "cards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le mot"])},
      "hear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entendre le mot"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réordonné les mots"])},
      "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduire le mot"])},
      "battle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bataille de vitesse"])},
      "contest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dictée"])},
      "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrire le mot"])},
      "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre des lettres"])},
      "words-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots en ordre alphabétique"])},
      "missing-letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter le mot"])},
      "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
      "guess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deviner"])},
      "hangman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez le mot"])},
      "lick-3d-game": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux de mouche"])}
    },
    "braintreeStatus": {
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])},
      "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiré"])},
      "pastdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])}
    },
    "difficulty": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débutant"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaire"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancé"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert"])}
    },
    "paymentTypes": {
      "renew-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouvellement de licence"])},
      "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat de licence"])},
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat d'abonnement"])}
    }
  },
  "modal": {
    "tutorialVideo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier pas sur Adimo"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez regarder la vidéo ci-dessous pour apprendre comment utiliser la plateforme Adimo pour la première fois."])}
    },
    "needLicense": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonctionnalité est seulement disponible avec une license."])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter une license"])}
    },
    "newGameInvitation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez reçu une invitation de partie en ligne"])},
      "btnClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas rejoindre la partie"])}
    },
    "deleteItem": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez confirmer votre action"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'élément \"", _interpolate(_named("title")), "\" sera supprimé définitivement et ne sera plus disponible."])},
      "descriptionNoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'élément sera supprimé définitivement et ne sera plus disponible."])},
      "deletingClassroomDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les étudiants dans cette classe sera supprimé et ne pourra plus jouer. Veuillez changer leur classe si vous voulez garder ces étudiants."])},
      "deletingStudentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette étudiant sera supprimé et ne pourra plus jouer. Veuillez changer sa classe si vous voulez garder cette étudiant."])}
    },
    "finishedSession": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre session est fini!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vous reconnecter pour ouvrir une nouvelle session"])},
      "btnLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se reconnecter"])}
    },
    "closedSocket": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un autre appareil viens d'ouvrir une session avec votre compte!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez utiliser votre nouvelle appareil pour jouer avec votre compte"])},
      "btnLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se reconnecter"])}
    },
    "noLicense": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton abonnement a pris fin!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande à tes parents ou ton professeur si tu veux continuer à jouer sur Adimo."])},
      "loginWithParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter en tant que parent"])},
      "loginWithTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter en tant que professeur"])}
    },
    "classroomPdf": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["PDF de la classe: ", _interpolate(_named("title"))])}
    },
    "buyLicenses": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter des licences"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter des licences vous permet de créer des étudiants. Vous pouvez acheter les licences vous-même ou votre directeur d'école peut vous les transférer."])},
      "descriptionSchoolManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter des licences vous permet de créer des étudiants. Vous allez pouvoir ensuite les transférer à vos professeurs par courriel."])},
      "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix total"])}
    },
    "editLicense": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer des licenses"])},
      "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " licences sera transféré de ", _interpolate(_named("oldEmail")), " vers ", _interpolate(_named("newEmail"))])}
    },
    "paymentMethod": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de paiement"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une méthode de paiement qui sera utilisée pour le renouvellement automatique. Vous ne serez pas facturé avant la fin de l'abonnement actuel et nous vous tiendrons averti le moment venu. Vous pourrez annuler le renouvellement à tout moment d'ici là."])}
    },
    "cancelSubscription": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Annuler l'abonnement de ", _interpolate(_named("name"))])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'abonnement empêchera l'étudiant de se connecter et de jouer"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'abonnement"])}
    },
    "joinGame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre une partie avec un code"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander a votre professeur ou parent de vous donner le code"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre la partie"])},
      "noGameFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune partie trouvé pour ce code"])}
    },
    "leaveGame": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Veux-tu vraiment quitter la partie ?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les points accumulés seront perdus."])}
    },
    "anonymousAccount": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jouer sans compte"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Veuillez remplir ce formulaire avant de pouvoir jouer sans compte"])}
    },
    "anonymousNoGameLeft": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vous créer un compte"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez plus de partie disponible. Veuillez vous créer un compte pour suivre vos progrès. Vous pouvez toujours rafraichir votre navigateur pour recommencer sans compte."])}
    },
    "wordGroup": {
      "addFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un filtre"])},
      "filterFromExisting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer à partir d'une liste préfabriquée"])},
      "addedWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots ajoutés"])},
      "availableWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots"])},
      "pleaseFilterWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez filtrer les mots pour en voir plus..."])}
    },
    "duplicateListInListGroup": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dupliquer les listes préfabriquées dans \"", _interpolate(_named("title")), "\""])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner les listes préfabriquées que vous voulez dupliquer."])}
    },
    "duplicateWordGroup": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dupliquer la liste de mots \"", _interpolate(_named("title")), "\""])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner l'ensemble de liste dans laquelle vous voulez dupliquer la liste de mots."])}
    },
    "cancelTeacherSubscription": {
      "titleSelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler votre abonnement"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Annuler l'abonnement pour ", _interpolate(_named("name"))])},
      "descriptionSelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler votre abonnement empêchera tous vos étudiants de se connecter et de jouer"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'abonnement empêchera ces étudiants de se connecter et de jouer"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'abonnement"])}
    },
    "cancelParentSubscription": {
      "titleSelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler votre abonnement"])},
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Annuler l'abonnement pour ", _interpolate(_named("name"))])},
      "descriptionSelf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler votre abonnement empêchera tous vos enfants de se connecter et de jouer"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'abonnement empêchera ces enfants de se connecter et de jouer"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'abonnement"])}
    },
    "createTeacher": {
      "btnCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un professeur"])},
      "btnAssignByEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver le professeur par courriel"])}
    }
  },
  "form": {
    "refCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de référence"])},
    "hasRefCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai un code de référence"])},
    "btnTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps"])},
    "btnMoney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argent"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "teacherName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professeur"])},
    "teacherEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel du professeur"])},
    "buyerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheteur"])},
    "buyerEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel de l'acheteur"])},
    "licenseCountWanted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de licences voulus"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
    "usernameOrEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel / Nom d'utilisateur"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexe"])},
    "autoGeneratedIfEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Généré automatiquement si vide"])},
    "notChangedIfEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non changé si vide"])},
    "genderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permet une personnalisation des produits"])},
    "classroomCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de la classe"])},
    "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "shortcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de connexion"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue maternelle"])},
    "learningLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue d'apprentissage"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année scolaire"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code unique"])},
    "difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "wordGroupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la liste"])},
    "listGroupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'ensemble"])},
    "connectedListGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensemble de liste connecté"])},
    "allListWordGroupWillBeShown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les listes préfabriquées seront montrées aux étudiants si aucun ensemble de liste connecté n'est sélectionné."])},
    "listGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensemble de liste"])},
    "leagueTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la ligue"])},
    "leagueType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de ligue"])},
    "leaguePublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publique"])},
    "leaguePrivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privée"])},
    "classroomTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de la classe"])},
    "classroomLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue d'apprentissage de la classe"])},
    "newPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
    "gameCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de la partie"])},
    "confirmNewPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le nouveau mot de passe"])},
    "createPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer votre mot de passe"])},
    "confirmPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer votre mot de passe"])},
    "acceptTerms1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte les"])},
    "acceptTerms2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conditions d'utilisation"])},
    "acceptTerms3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et la"])},
    "acceptTerms4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politique de confidentialité"])},
    "acceptTerms5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Adimo"])},
    "acceptAds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte de recevoir des offres exclusives et promotions provenant de Adimo et ses partenaires."])},
    "editLeague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la ligue"])},
    "createLeague": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une ligue"])},
    "createClassroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une classe"])},
    "createListGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un ensemble"])},
    "createWordGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une liste de mot"])},
    "createStudent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un étudiant"])},
    "createChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un enfant"])},
    "duplicateListInListGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer les listes préfabriquées dans cette liste"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "btnStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer"])},
    "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "btnQuit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter"])},
    "btnFinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finaliser"])},
    "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "btnEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "btnDuplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer"])},
    "btnDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "btnCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
    "btnBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
    "btnCreateSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer le professeur et acheter l'abonnement"])},
    "btnAddSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter l'abonnement"])},
    "extraWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots à ajouter"])},
    "duplicateWordGroupFromCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer une liste de mot à partir d'un code"])},
    "duplicateWordGroupFromCodeInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])}
  },
  "flash": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
    "leagueInvitationSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre invitation a été envoyée"])},
    "accountSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a bien été modifié"])},
    "errorOccurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite."])},
    "noMoreLicenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez plus de licenses disponible."])},
    "noMoreLicensesTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre professeur n'a plus de licenses"])},
    "duplicateWordGroup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre liste de mot a été dupliquée dans \"", _interpolate(_named("title")), "\" avec succès"])},
    "duplicateListInListGroup": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vos listes de mots ont bien été dupliqués dans \"", _interpolate(_named("title")), "\" "])}
  },
  "validationErrors": {
    "refCodeDontExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de référence n'existe pas"])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un forfait"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner votre langue maternelle"])},
    "learningLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner votre langue d'apprentissage"])},
    "shortcodeDontExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de connexion n'existe pas"])},
    "missingField": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ \"", _interpolate(_named("field")), "\" est nécessaire"])},
    "invalidFieldId": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le id ", _interpolate(_named("field")), " ext invalide"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner l'année scolaire"])},
    "invalidFieldEnum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le champ enum ", _interpolate(_named("field")), " n'est pas valide. Les valeurs valide sont: ", _interpolate(_named("values"))])},
    "invalidDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La date (", _interpolate(_named("field")), ") est invalide. Format requis (DD-MM-YYYY)"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code unique ne peut pas être vide"])},
    "tokenDontExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code unique n'existe pas"])},
    "tokenInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code unique est invalide"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prénom ne peut pas être vide"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom ne peut pas être vide"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom ne peut pas être vide"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le titre ne peut pas être vide"])},
    "titleLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le titre doit être plus long que 3 caractères"])},
    "classroomTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le titre de la classe ne peut pas être vide"])},
    "classroomGrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'année scolaire de la classe ne peut pas être vide"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur ne peut pas être vide"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le courriel ne peut pas être vide"])},
    "licenseCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez verifier votre nombre de license"])},
    "sameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le courriel doit ne doit pas être le meme que l'ancien"])},
    "emailExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le courriel est déjà utilisé par un autre utilisateur"])},
    "emailDontExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le courriel n'existe pas"])},
    "usernameDontExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur n'existe pas"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne peut pas être vide"])},
    "passwordLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit être plus long que 6 caractères"])},
    "passwordInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe est invalide"])},
    "passwordDontMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mot de passe ne sont pas identique"])},
    "usernameTaken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur est déjà pris"])},
    "league": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ligue ne peut pas être vide"])},
    "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La classe ne peut pas être vide"])},
    "max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " est le maximum"])},
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " est le minimum"])},
    "listGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un ensemble de liste"])},
    "userExistAsParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le compte existe deja mais comme parent"])},
    "acceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez accepter les termes et conditions"])},
    "wordIdsLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez ajouter au moins 10 mots"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez ajouter ou sélectionner un mode de paiement"])},
    "paymentMethodNotValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mode de paiement n'est pas valide"])},
    "leagueLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom de la ligue doit être plus long que 3 caractères"])},
    "gameCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code ne peut pas être vide"])},
    "maxSecondsToAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le temps de réponse ne peut pas être vide. Doit être entre 5 et 40 secondes."])},
    "maxLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur fourni est trop longue"])},
    "tooPricey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prix total est trop haut, veuillez reduire votre facture"])},
    "langMatching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le langue maternelle ne peut pas être la langue d'apprentissage"])},
    "classroomCodeDontExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de la classe n'existe pas"])},
    "wordsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de mots ne peut pas être vide."])},
    "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un professeur"])}
  },
  "emails": {
    "deletedSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouvellement annulé pour un de vos abonnements"])},
    "reactivateSubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement activé"])},
    "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été modifié"])},
    "forgotPwd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
    "verifiedEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre courriel"])}
  },
  "pdf": {
    "invoice": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Facture: #", _interpolate(_named("uuid"))])},
      "merchantTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information du vendeur"])},
      "merchantName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur"])},
      "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison"])},
      "transactionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de la transaction"])},
      "transactionTypeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "transactionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente"])},
      "tpsAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Montant TPS (#", _interpolate(_named("code")), ")"])},
      "tvqAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Montant TVQ (#", _interpolate(_named("code")), ")"])},
      "taxAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant taxes"])},
      "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total"])},
      "transactionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la transaction"])},
      "processorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code du processeur"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "paymentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de paiement"])},
      "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte de crédit"])},
      "notCreditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas par carte de crédit"])},
      "paymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de paiement"])},
      "cardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de carte"])},
      "cardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de carte"])},
      "cardExpiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration de la carte"])},
      "customerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de l'acheteur"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])}
    }
  },
  "multi": {
    "words": {
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Words"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabras"])}
    }
  },
  "constants": {
    "months": {
      "01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janvier"])},
      "02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Février"])},
      "03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])},
      "04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril"])},
      "05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
      "06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juin"])},
      "07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juillet"])},
      "08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Août"])},
      "09": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septembre"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octobre"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décembre"])}
    }
  }
}