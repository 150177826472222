<template>
    <modal
        :show="true"
        @close="$emit('close')">
        <h2 class="mb-6 text-center">
            {{ $t("modal.deleteItem.title") }}
        </h2>
        <p class="my-4 text-purple -small text-center">
            <span v-if="itemTitle">
            {{ $t("modal.deleteItem.description", { title: itemTitle }) }}
            </span>
            <span v-else>
                {{ $t("modal.deleteItem.descriptionNoTitle") }}
            </span>
        </p>
        <slot />
        <div class="flex flex-row justify-center items-center my-4 w-full">
            <Loader v-if="isLoading" />
            <CustomButton
                v-else
                @click.prevent="$emit('delete')">
                {{ $t("form.btnDelete") }}
            </CustomButton>
        </div>
    </modal>
</template>

<script>
import Modal from "components/globals/Modal"
import CustomButton from "components/globals/CustomButton"
import Loader from "components/Loader"

export default {
    name: "DeleteItemModal",
    components: { Loader, CustomButton, Modal },
    emits: ["close", "delete"],
    props: {
        isLoading: {
            default: false,
            type: Boolean
        },
        itemTitle: String
    }
}
</script>
